import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../static/footer.css';
import { FaPhone } from 'react-icons/fa';
import { FaMailBulk } from 'react-icons/fa';


const FooterPage = () => {
  return (
    <footer className="font-small pt-4 mt-4">
      <container fluid className="text-center text-md-left">
        <row>
          <container col md="6">
            <h5 className="title">VMS DTP</h5>
            
          </container>
          <container col md="6">
            <h5 className="title">Contact us: </h5>
            <p><FaPhone /> +91 xxxxxxxxxx <br />
            <FaMailBulk /> vinod.mathew.sebastian@gmail.com </p>
            <h5 className="title">Quick Links:</h5>
            <ul >
            <li className="list-unstyled text-center text-md-left">
                <a href="/">Home</a>
              </li>
              <li className="list-unstyled text-center text-md-left">
                <a href="/services">Services </a>
              </li>
              <li className="list-unstyled text-center text-md-left">
                <a href="/contact">Contact</a>
              </li>
              </ul>
          </container>
        </row>
      </container>
      <div className="footer-copyright text-center py-3">
        <container fluid>
          &copy; {new Date().getFullYear()} <a href="https://vinod-vms.netlify.com" target="_blank"> VMS </a>
        </container>
      </div>
    </footer>
  );
}

export default FooterPage;